<template>

  <ckeditor
    v-model="editorData"
    class="border"
    :editor="editor"
    :config="editorConfig"
    @ready="onReady"
    @input="onEditorChange"
  />
</template>

<script>

import CKEditor from '@ckeditor/ckeditor5-vue2';
import Editor from '@/libs/ckeditor5/build/ckeditor';

export default {
  components: {
    ckeditor: CKEditor.component,
  },
  props: {
    defaultValue: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      editor: Editor,
      editorData: this.defaultValue,
      editorConfig: {
        fontSize: {
          options: [
            9,
            10,
            11,
            12,
            13,
            'default',
            15,
            16,
            17,
            18,
            19,
            20,
            21,
            22,
            23,
            24,
          ],
        },
      },
    };
  },
  watch: {
    defaultValue(val) {
      this.editorData = val;
    },
  },
  setup() {

  },
  methods: {
    onReady(editor) {
      // Insert the toolbar before the editable area.
      editor.ui.getEditableElement().parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement(),
      );
    },
    onEditorChange() {
      this.$emit('input', this.editorData);
      console.log(this.editorData);
    },
  },
};
</script>
